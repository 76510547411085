import { IProcessRequest } from "@epam/uui-core";
import { IRiskData, IRiskRequest } from "./riskTypes";
import { IIksApiResponse } from "../baseTypes";

export function getRisksApi(processRequest: IProcessRequest, origin: string) {
    return {
        getUserRisks(req: IRiskRequest) {
            const { token } = req;
            return processRequest<IIksApiResponse<IRiskData[]>>(
                `${origin}/api/v1/risks`,
                "POST",
                {
                    searchText: "",
                    filter: {},
                },
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}
