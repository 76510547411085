import React, { useCallback, useMemo, useState } from "react";
import {
    DataColumnProps,
    DataQueryFilter,
    IModal,
    TableFiltersConfig,
    useAsyncDataSource,
    useTableState,
    useUuiContext,
    UuiContexts,
} from "@epam/uui-core";
import {
    Button,
    DataTable,
    FiltersPanel,
    FlexCell,
    FlexRow,
    ModalBlocker,
    ModalFooter,
    ModalHeader,
    ModalWindow,
    Panel,
    PresetsPanel,
    ScrollBars,
    SearchInput,
    Text,
    IconButton,
} from "@epam/uui";
import { ReactComponent as AddIcon } from "@epam/assets/icons/action-add-outline.svg";

import { AppFooter, AppHeader, Page } from "../common";
import { SidebarDataPanel } from "../common/SidebarDataPanel";
import { useAppUser } from "../helpers/appUser";
import { TApi } from "../data/apiDefinition";
import { IRiskData } from "../data/api";
import { getColumnConfiguration } from "./RiskColumnsConfig.v1";
import { getFilterConfiguration } from "./RiskFiltersConfig.v1";
import { getDataConfiguration } from "./RiskDataConfig.v1";
import { getRiskPresets } from "./RiskPresetsConfig";
import { applyFilters } from "./RiskFiltersUtils";
import { useNotifications, useRiskData } from "../hooks";

import css from "./RiskPage.module.scss";

export const RiskPage = () => {
    const userContext = useAppUser();
    const svc = useUuiContext<TApi, UuiContexts>();
    const [riskData, setRiskData] = useState<IRiskData[]>([]);
    const [dataPanelVisible, setDataPanelVisible] = useState(false);
    const [dataPanelEditable, setDataPanelEditable] = useState(false);
    const [showPresets, setShowPresets] = useState<boolean>(true);
    const [hasFetchedData, setHasFetchedData] = useState<boolean>(false);
    const { showSuccess, showWarning } = useNotifications();
    const { fetchRiskData, fetchDummyData } = useRiskData();
    const isDevEnv = process.env.REACT_APP_ENV_ISDEV === "1";

    if (!userContext?.hasUser()) {
        svc.uuiRouter.redirect({ pathname: "/" });
    }

    const refreshData = () => {
        setHasFetchedData(false);
    };

    // Actions
    const handleDeleteClick = (risk: IRiskData) => {
        // Infer input and add a UUI-based popup to confirm deletion
        svc.uuiModals
            .show<string>((props) => renderDeleteModal(props, risk)) // Pass risk object to the modal
            .then((result) => {
                showSuccess(result);
            })
            .catch(() => {
                showWarning("Cancelling");
            });
    };

    // Filters
    const filtersConfig = useMemo<TableFiltersConfig<IRiskData>[]>(
        () => getFilterConfiguration(riskData),
        [riskData],
    );

    // Columns
    const riskColumns: DataColumnProps<IRiskData>[] = useMemo(
        () => getColumnConfiguration((risk) => handleDeleteClick(risk)),
        [],
    );

    // Data state
    const tableStateApi = useTableState<DataQueryFilter<IRiskData>>({
        filters: filtersConfig,
        initialPresets: getRiskPresets,
    });

    const riskDataSource = useAsyncDataSource<
        IRiskData,
        number,
        DataQueryFilter<IRiskData>
    >(
        {
            api: async () => {
                console.log("has fetched", hasFetchedData);
                console.log("filter state is: ", tableState.filter);

                let data = riskData;
                if (!hasFetchedData) {
                    data = isDevEnv
                        ? await fetchDummyData()
                        : await fetchRiskData(userContext?.getToken() || "");
                    setRiskData(data);
                    setHasFetchedData(true);
                }

                // No need for setRiskData or hasFetchedData here
                return applyFilters(
                    data,
                    filtersConfig,
                    tableStateApi.tableState,
                );
            },
            selectAll: false,
        },
        [tableStateApi.tableState],
    );

    // View
    const view = riskDataSource.useView(
        tableStateApi.tableState,
        tableStateApi.setTableState,
        {
            getId: (item) => item.id,
            getRowOptions: (item: IRiskData, index?: number) => ({
                checkbox: { isVisible: false },
                isSelectable: true,
                onClick(rowProps) {
                    setDataPanelData(item);
                    setDataPanelVisible(true);
                },
            }),
            getSearchFields: (item: IRiskData) => [
                item.number,
                item.type,
                item.description,
            ],
        },
    );

    // Sidebar data panel
    const sidebarData =
        tableStateApi.tableState.selectedId &&
        view.getById(tableStateApi.tableState.selectedId, 0).value;
    const sidebarDataConfig = useMemo(() => getDataConfiguration, []);
    const [dataPanelData, setDataPanelData] = useState<IRiskData | null>(
        sidebarData,
    );

    const closeDataPanel = useCallback(() => {
        console.log("Closing data panel");
        setDataPanelVisible(false);
    }, []);

    // Search handler

    const searchHandler = (val: string | undefined) => {
        console.log("searching for: ", val);
        tableStateApi.setTableState({
            ...tableStateApi.tableState,
            search: val,
        });
    };

    const {
        tableState,
        setTableState,
        setFilter,
        setColumnsConfig,
        setFiltersConfig,
        ...presetsApi
    } = tableStateApi;

    // Rendering

    const renderDeleteModal = (modalProps: IModal<string>, risk: IRiskData) => {
        return (
            <ModalBlocker
                disallowClickOutside
                {...modalProps}
                disableCloseByEsc={true}
            >
                <ModalWindow>
                    <Panel background="surface-main">
                        <ModalHeader
                            title="Confirm"
                            onClose={() => modalProps.abort()}
                        />
                        <ScrollBars hasTopShadow hasBottomShadow>
                            <FlexRow padding="24">
                                <Text size="36">
                                    Sind Sie sicher, dass Sie das Risiko
                                    deaktivieren wollen?
                                </Text>
                            </FlexRow>
                        </ScrollBars>
                        <ModalFooter cx={css.footer}>
                            <Button
                                color="secondary"
                                fill="outline"
                                caption="Cancel"
                                onClick={() => modalProps.abort()}
                            />
                            <Button
                                color="primary"
                                caption="Ok"
                                onClick={() =>
                                    modalProps.success("Success action")
                                }
                            />
                        </ModalFooter>
                    </Panel>
                </ModalWindow>
            </ModalBlocker>
        );
    };

    const renderHeader = React.useCallback(() => {
        return <AppHeader showPageMenu={true} />;
    }, []);

    const renderFooter = React.useCallback(() => {
        return <AppFooter />;
    }, []);

    const pageContent = (
        <>
            <div className={css.wrapper}>
                <div className={css.container}>
                    <div className={css.headerPanel}>
                        <div className={css.headerPanelWrapper}>
                            <div className={css.header}>
                                <div className={css.headerText}>Risiken</div>
                                <div className={css.addButtonWrap}>
                                    <IconButton
                                        icon={AddIcon}
                                        cx={css.addButton}
                                        color={"white"}
                                        onClick={() => {
                                            console.log("Add risk");
                                            setDataPanelVisible(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css.presetsPanel}>
                        {showPresets && <PresetsPanel {...tableStateApi} />}
                    </div>
                    <FlexRow cx={css.filterPanelWrapper} borderBottom={true}>
                        <FlexRow cx={css.filterPanel}>
                            <FiltersPanel
                                filters={filtersConfig}
                                tableState={tableState}
                                setTableState={setTableState}
                            />
                            <Button
                                caption="Reset"
                                onClick={() => {
                                    tableStateApi.setFilter({});
                                }}
                                color="primary"
                            />
                            <Button
                                caption="Import"
                                onClick={() => {
                                    tableStateApi.setFilter({});
                                }}
                                color="accent"
                            />
                            <Button
                                caption="Export"
                                onClick={() => {
                                    tableStateApi.setFilter({});
                                }}
                                color="accent"
                            />
                        </FlexRow>
                        <FlexCell cx={css.search} width={295}>
                            <SearchInput
                                value={tableStateApi.tableState.search}
                                onValueChange={searchHandler}
                                placeholder="Suchen"
                                debounceDelay={1000}
                            />
                        </FlexCell>
                    </FlexRow>
                    <DataTable
                        headerTextCase="upper"
                        getRows={view.getVisibleRows}
                        columns={riskColumns}
                        value={tableStateApi.tableState}
                        onValueChange={tableStateApi.setTableState}
                        showColumnsConfig={true}
                        allowColumnsReordering={true}
                        allowColumnsResizing={true}
                        {...view.getListProps()}
                    />
                </div>
                <SidebarDataPanel
                    data={dataPanelData}
                    fields={sidebarDataConfig}
                    title="Risk details"
                    isVisible={dataPanelVisible}
                    isEditable={dataPanelEditable}
                    onClose={() => {
                        closeDataPanel();
                    }}
                />
            </div>
        </>
    );
    return (
        <Page
            contentCx={css.root}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isProtectedPage={true}
        >
            {pageContent}
        </Page>
    );
};
