import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { IIksUserTokenResponse, IIksUserProfileResponse } from "src/data/api";
import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "src/data/apiDefinition";

export type TAppUserContext = {
    userProfileData?: IIksUserProfileResponse | null;
    hasUser: () => boolean;
    getToken: () => string | undefined;
    addUser: (user: IIksUserTokenResponse) => void;
    removeUser: () => void;
    // refreshToken: () => Promise<boolean>;
};

/***********************/
// Context
/***********************/
const AppUserContext = React.createContext<TAppUserContext | null>(null);

/***********************/
// Hook
/***********************/
export const useAppUser = () => {
    return useContext(AppUserContext);
};

/***********************/
// Provider
/***********************/
export const AppUserProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const svc = useUuiContext<TApi, UuiContexts>();
    const [userTokenData, setUserTokenData] =
        useLocalStorage<IIksUserTokenResponse | null>("iks_u_t", null);
    const [userProfileData, setUserProfileData] =
        useState<IIksUserProfileResponse | null>(null);

    const addUser = (data: IIksUserTokenResponse) => {
        setUserTokenData(data);
    };

    const removeUser = () => {
        setUserTokenData(null);
        setUserProfileData(null);
        svc.uuiRouter.redirect({ pathname: "/" });
    };

    const hasUser = (): boolean => {
        return userProfileData != null && userTokenData?.access.token != null;
    };

    const getToken = (): string | undefined => {
        return userTokenData?.access.token;
    };

    const isTokenExpired = (expiresAt: string): boolean =>
        new Date() >= new Date(expiresAt);

    const refreshToken = async (): Promise<boolean> => {
        if (
            !userTokenData?.refresh.token ||
            isTokenExpired(userTokenData.refresh.expiresAt)
        ) {
            return false;
        }

        try {
            const newTokenResponse = await svc.api.auth.refreshToken({
                id: userTokenData.id,
                token: userTokenData.refresh.token,
            });

            if (newTokenResponse != null && newTokenResponse.data !== null) {
                addUser(newTokenResponse.data);
                return true;
            } else {
                removeUser();
                return false;
            }
        } catch (error) {
            console.error("Error refreshing token:", error);
            removeUser();
            return false;
        }
    };

    const fetchUserProfile = async () => {
        if (
            !userTokenData?.access.token ||
            isTokenExpired(userTokenData.access.expiresAt)
        ) {
            return false;
        }

        try {
            const profile = await svc.api.auth.getUserProfile({
                token: userTokenData.access.token,
            });
            setUserProfileData(profile.data);
        } catch (error) {
            console.error("Error fetching user profile:", error);
            removeUser();
        }
    };

    useEffect(() => {
        // Only proceed if userTokenData exists and the profile isn't already fetched
        if (!userTokenData || userProfileData) return;

        const handleProfileFetch = async () => {
            if (!isTokenExpired(userTokenData.access.expiresAt)) {
                // Fetch profile if access token is valid
                await fetchUserProfile();
            } else if (!isTokenExpired(userTokenData.refresh.expiresAt)) {
                // Refresh token if expired, then fetch profile
                const refreshed = await refreshToken();
                if (refreshed) {
                    await fetchUserProfile();
                }
            }
        };

        handleProfileFetch();
        // Adding an empty dependency to ensure it runs only when userTokenData changes
    }, [userTokenData]);

    const value = useMemo(
        () => ({
            userProfileData,
            hasUser,
            getToken,
            addUser,
            removeUser,
        }),
        [userTokenData, userProfileData],
    );

    return (
        <AppUserContext.Provider value={value}>
            {children}
        </AppUserContext.Provider>
    );
};
