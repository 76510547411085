import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "../data/apiDefinition";
import { IRiskData, riskDummyData } from "../data/api";

export const useRiskData = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const fetchRiskData = async (token: string) => {
        if (!token) return [];
        try {
            const response = await svc.api.risks.getUserRisks({ token });
            return response?.data || [];
        } catch (error) {
            console.error("Error fetching risk data:", error);
            return [];
        }
    };

    const fetchDummyData = async (): Promise<IRiskData[]> => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(riskDummyData.data), 100);
        });
    };

    return { fetchRiskData, fetchDummyData };
};
