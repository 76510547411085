import { ITablePreset } from "@epam/uui-core";

export const getRiskPresets: ITablePreset[] = [
    {
        id: -1,
        name: "Active",
        isReadonly: true,
        filter: {
            isActive: true,
        },
    },
    {
        id: -2,
        name: "Inactive",
        isReadonly: true,
        filter: {
            isActive: false,
        },
    },
];
