import React from "react";
import {
    FlexCell,
    FlexRow,
    FlexSpacer,
    IconButton,
    Panel,
    Text,
} from "@epam/uui";
import { ScrollBars } from "@epam/uui-components";
import { ReactComponent as CrossIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { cx } from "@epam/uui-core";
import { SidebarDataSection } from "./SidebarDataSection";
import css from "./SidebarDataPanel.module.scss";

export interface SidebarDataItemConfig<T> {
    title: string;
    render?: (data: T) => React.ReactNode;
}

export interface SidebarDataPanelProps<T> {
    title: string;
    // The data object to display or edit
    data: T | null;
    // Field configuration array
    fields: SidebarDataItemConfig<T>[];
    // Whether the panel is visible
    isVisible: boolean;
    // Whether the panel is in editable mode
    isEditable?: boolean;
    // Handler for on close event
    onClose(): void;
}

export function SidebarDataPanel<T>({
    title,
    data,
    fields,
    isVisible,
    isEditable,
    onClose,
}: SidebarDataPanelProps<T>) {
    if (!data) {
        return null;
    }

    return (
        <div
            className={cx(
                css.infoSidebarPanelWrapper,
                isVisible ? "show" : "hide",
            )}
        >
            <Panel cx={css.wrapper}>
                <FlexRow borderBottom padding="24">
                    <Text size="48" fontWeight="600">
                        {title}
                    </Text>
                    <FlexSpacer />
                    <FlexCell shrink={0} width="auto">
                        <IconButton icon={CrossIcon} onClick={onClose} />
                    </FlexCell>
                </FlexRow>
                <ScrollBars>
                    <SidebarDataSection
                        data={data}
                        fields={fields}
                        isEditable={isEditable}
                    />
                </ScrollBars>
            </Panel>
        </div>
    );
}
