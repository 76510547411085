import { Badge, FlexRow, Text } from "@epam/uui";
import { IconContainer } from "@epam/uui-components";
import { SidebarDataItemConfig } from "src/common/SidebarDataPanel";
import { IRiskData } from "src/data/api";
import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";

const renderLevelIndicator = (level?: { id?: number; name?: string }) => {
    if (!level) {
        return null;
    }

    let color: "success" | "warning" | "critical" | undefined;

    switch (level.id) {
        case 1:
            color = "success";
            break;
        case 2:
            color = "warning";
            break;
        case 3:
            color = "critical";
            break;
        default:
            return null;
    }

    return (
        <FlexRow>
            <Badge
                size="24"
                indicator
                fill="outline"
                color={color}
                caption={level.name || ""}
            />
        </FlexRow>
    );
};

export const getDataConfiguration: SidebarDataItemConfig<IRiskData>[] = [
    {
        title: "Nr.",
        render: (risk) => {
            return <Text>{risk.number}</Text>;
        },
    },
    {
        title: "Risikoart",
        render: (risk) => {
            return <Text>{risk.type}</Text>;
        },
    },
    {
        title: "Schlüsselrisiko",
        render: (risk) => {
            return (
                <FlexRow>
                    <IconContainer
                        icon={IsImporantIcon}
                        style={{
                            fill: risk.isImportant ? "#88CC00" : "#ccc",
                        }}
                    />
                </FlexRow>
            );
        },
    },
    {
        title: "Beschreibung Risiko",
        render: (risk) => <Text>{risk.description}</Text>,
    },
    {
        title: "IKS-Ziele",
        render: (risk) => <Text>{risk.iksObjective}</Text>,
    },
    {
        title: "Brutto-Schaden",
        render: (risk) =>
            renderLevelIndicator(risk.grossAssessment?.damageLevel),
    },
    {
        title: "Brutto-Eintreten",
        render: (risk) =>
            renderLevelIndicator(risk.grossAssessment?.eventLevel),
    },
    {
        title: "Brutto-Risiko",
        render: (risk) => renderLevelIndicator(risk.grossAssessment?.riskLevel),
    },
    {
        title: "Risikoindikator",
        render: (risk) => (
            <Text>{risk.grossAssessment?.riskIndicatorText}</Text>
        ),
    },
    {
        title: "Existierende Massnahmen",
        render: (risk) => <Text>{risk.grossAssessment?.existingMeasures}</Text>,
    },
    {
        title: "Netto-Schaden",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.damageLevel),
    },
    {
        title: "Netto-Eintreten",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.eventLevel),
    },
    {
        title: "Netto-Risiko",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.riskLevel),
    },
    {
        title: "initiierte, geplante Massnahmen",
        render: (risk) => (
            <Text>{risk.netAssessment?.initiatedOrPlannedMeasures}</Text>
        ),
    },
];
