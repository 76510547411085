import {
    DataQueryFilter,
    IProcessRequest,
    LazyDataSourceApiRequest,
    LazyDataSourceApiResponse,
} from "@epam/uui-core";
import { IIksApiResponse } from "../baseTypes";
import {
    IIksAuthenticateRequest,
    IIksRefreshTokenRequest,
    IIksUserTokenResponse,
    IIksUserProfileRequest,
    IIksUserProfileResponse,
} from "./authTypes";

export function getAuthApi(
    processRequest: IProcessRequest,
    origin: string = "",
) {
    function lazyApi<TEntity, TId>(name: string) {
        return (
            rq: LazyDataSourceApiRequest<
                TEntity,
                TId,
                DataQueryFilter<TEntity>
            >,
        ) =>
            processRequest<LazyDataSourceApiResponse<TEntity>>(
                origin.concat("/api/").concat(name),
                "POST",
                rq,
            );
    }

    return {
        authenticateUser(req: IIksAuthenticateRequest) {
            return processRequest<IIksApiResponse<IIksUserTokenResponse>>(
                origin.concat("/api/auth/login"),
                "POST",
                req,
            );
        },
        refreshToken(req: IIksRefreshTokenRequest) {
            return processRequest<IIksApiResponse<IIksUserTokenResponse>>(
                origin.concat("/api/auth/refresh"),
                "POST",
                req,
            );
        },
        getUserProfile(req: IIksUserProfileRequest) {
            var { token } = req;
            return processRequest<IIksApiResponse<IIksUserProfileResponse>>(
                origin.concat("/api/auth/me"),
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}
