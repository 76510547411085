import * as React from "react";
import { Anchor, Badge, CountIndicator, Text } from "@epam/uui";

import { DashboardItem } from "./DashboardItem";

import css from "./DashboardItemCard.module.scss";

export interface IDashboardItemCard {
    dashboardItem: DashboardItem;
}
export function DashboardItemCard(props: IDashboardItemCard) {
    const {
        dashboardItem: {
            name,
            description,
            navigationPath,
            backgroundColor,
            countIndicator,
        },
    } = props;

    return (
        <Anchor
            cx={css.container}
            key={name}
            link={{
                pathname: navigationPath,
            }}
        >
            {/* <div
                className={css.navCard}
                style={{ backgroundColor: `${backgroundColor}` }}
            />
            <div className={css.navDescription}>
                <Text
                    cx={css.title}
                    fontWeight="600"
                    lineHeight="30"
                    fontSize="24"
                >
                    {name}
                </Text>
                <div className={css.description}>
                    <Text fontSize="12">{description}</Text>
                </div>
            </div> */}

            <div
                className={css.navCard}
                style={{ backgroundColor: `${backgroundColor}` }}
            >
                <Text
                    cx={css.title}
                    fontWeight="600"
                    lineHeight="30"
                    fontSize="24"
                >
                    {name}
                </Text>
                <div className={css.description}>
                    <Text fontSize="18" cx={css.descriptionText}>
                        {description}
                    </Text>
                    {countIndicator && (
                        <div className={css.itemsToAction}>
                            <CountIndicator
                                caption={countIndicator}
                                color="warning"
                                size="24"
                            />
                        </div>
                    )}
                </div>
            </div>
        </Anchor>
    );
}
