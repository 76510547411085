import { DataColumnProps } from "@epam/uui-core";
import {
    Badge,
    FlexRow,
    IconButton,
    IconContainer,
    Text,
    Tooltip,
} from "@epam/uui";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";
import { ReactComponent as EditIcon } from "@epam/assets/icons/content-edit-fill.svg";
import { ReactComponent as DeleteIcon } from "@epam/assets/icons/action-delete-outline.svg";

import { IRiskData } from "src/data/api";

import css from "./RiskPage.module.scss";

const renderLevelIndicator = (level?: { id?: number; name?: string }) => {
    if (!level) {
        return null;
    }

    let color: "success" | "warning" | "critical" | undefined;

    switch (level.id) {
        case 1:
            color = "success";
            break;
        case 2:
            color = "warning";
            break;
        case 3:
            color = "critical";
            break;
        default:
            return null;
    }

    return (
        <FlexRow>
            <Badge
                size="24"
                indicator
                fill="outline"
                color={color}
                caption={level.name || ""}
            />
        </FlexRow>
    );
};

export const getColumnConfiguration = (
    handleDeleteClick: (risk: IRiskData) => void,
): DataColumnProps<IRiskData, number>[] => [
    {
        key: "number",
        caption: "Nr.",
        render: (risk) => (
            <Text color="secondary" fontWeight="600">
                {risk.number}
            </Text>
        ),
        width: 100,
        fix: "left",
        isSortable: true,
        isLocked: true,
    },
    {
        key: "type",
        caption: "Risikoart",
        render: (risk) => <Text>{risk.type}</Text>,
        width: 120,
        minWidth: 120,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
        isFilterActive: (i) => !!i.type,
    },
    {
        key: "isImportant",
        caption: (
            <IconContainer icon={IsImporantIcon} style={{ fill: "#88CC00" }} />
        ),
        render: (risk) => {
            return (
                <FlexRow>
                    <IconContainer
                        icon={IsImporantIcon}
                        style={{
                            fill: risk.isImportant ? "#88CC00" : "#ccc",
                        }}
                    />
                </FlexRow>
            );
        },
        renderTooltip: (risk) => {
            return (
                <Tooltip content="Is Key Risk?" color="inverted">
                    Is Key Risk?
                </Tooltip>
            );
        },
        width: 60,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
    },
    {
        key: "description",
        caption: "Beschreibung Risiko",
        render: (risk) => <Text>{risk.description}</Text>,
        width: 240,
        grow: 0,
        isLocked: true,
    },
    {
        key: "iksObjective",
        caption: "IKS-Ziele",
        render: (risk) => <Text>{risk.iksObjective}</Text>,
        width: 80,
        isHiddenByDefault: true,
    },
    {
        key: "grossDamageLevel",
        caption: "Brutto-Schaden",
        render: (risk) =>
            renderLevelIndicator(risk.grossAssessment?.damageLevel),
        width: 100,
    },
    {
        key: "grossEventLevel",
        caption: "Brutto-Eintreten",
        render: (risk) =>
            renderLevelIndicator(risk.grossAssessment?.eventLevel),
        width: 100,
    },
    {
        key: "grossRiskLevel",
        caption: "Brutto-Risiko",
        render: (risk) => renderLevelIndicator(risk.grossAssessment?.riskLevel),
        width: 100,
    },
    {
        key: "grossRiskIndicator",
        caption: "Risikoindikator",
        render: (risk) => (
            <Text>{risk.grossAssessment?.riskIndicatorText}</Text>
        ),
        width: 120,
    },
    {
        key: "grossExistingMeaures",
        caption: "Existierende Massnahmen",
        render: (risk) => <Text>{risk.grossAssessment?.existingMeasures}</Text>,
        width: 220,
    },
    {
        key: "netDamageLevel",
        caption: "Netto-Schaden",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.damageLevel),
        width: 100,
    },
    {
        key: "netEventLevel",
        caption: "Netto-Eintreten",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.eventLevel),
        width: 100,
    },
    {
        key: "netRiskLevel",
        caption: "Netto-Risiko",
        render: (risk) => renderLevelIndicator(risk.netAssessment?.riskLevel),
        width: 100,
    },
    {
        key: "netPlannedMeasures",
        caption: "initiierte, geplante Massnahmen",
        render: (risk) => (
            <Text>{risk.netAssessment?.initiatedOrPlannedMeasures}</Text>
        ),
        width: 220,
    },
    {
        key: "controlId",
        caption: "Kontrollen",
        render: (risk) => {
            return (
                <ul
                    style={{
                        padding: 0,
                        margin: 0,
                        listStyleType: "none",
                    }}
                >
                    {risk.controls?.map((rc) => (
                        <li key={rc.id}>
                            <FlexRow>
                                <Text>{rc.name}</Text>
                            </FlexRow>
                        </li>
                    ))}
                </ul>
            );
        },
        // renderHeaderCell(props) {
        //     console.log("props", props);
        //     return (
        //         <DataTableCellContainer
        //             {...props}
        //             cx={[
        //                 css.riskControlHeaderCell, // Custom styles for header
        //             ]}
        //         >
        //             <FlexRow cx={[css.captionWrapper]}>
        //                 <Text cx={[css.caption]}>Kontrollen</Text>
        //             </FlexRow>
        //         </DataTableCellContainer>
        //     );
        // },
        width: 120,
    },
    {
        key: "periodicityId",
        caption: "Periodizität",
        render: (risk) => {
            return (
                <ul
                    style={{
                        padding: 0,
                        margin: 0,
                        listStyleType: "none",
                    }}
                >
                    {risk.controls?.map((rc) => (
                        <li key={rc.id}>
                            <FlexRow>
                                <Text>{rc.periodicity?.name}</Text>
                            </FlexRow>
                        </li>
                    ))}
                </ul>
            );
        },
        width: 100,
    },
    {
        key: "detailed",
        render: () => {
            return (
                <div className={css.detailedActions}>
                    {/* <IconButton
                        cx={css.detailedIcon}
                        icon={ViewIcon}
                        size="18"
                    /> */}
                    <IconButton
                        cx={css.detailedIcon}
                        icon={EditIcon}
                        size="18"
                    />
                    <IconButton
                        cx={css.detailedIcon}
                        icon={DeleteIcon}
                        onClick={handleDeleteClick}
                        size="18"
                    />
                </div>
            );
        },
        width: 54,
        alignSelf: "center",
        fix: "right",
        allowResizing: false,
    },
];
