import React, { useEffect, useState } from "react";
import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { DashboardItemCard } from "./DashboardItemCard";
import { AppFooter, AppHeader, Page } from "../common";
import { dashboardItems } from "./DashboardItem";
import { useAppUser } from "../helpers/appUser";
import { TApi } from "src/data/apiDefinition";

import css from "./DashboardPage.module.scss";

export const DashboardPage = () => {
    const title = "";

    const renderHeader = React.useCallback(() => {
        return <AppHeader />;
    }, []);

    const renderFooter = React.useCallback(() => {
        return <AppFooter />;
    }, []);

    const pageContent = (
        <div className={css.navPage}>
            <div className={css.navTitle}>{title}</div>
            <div className={css.navCards}>
                {dashboardItems.map((item) => {
                    return (
                        <DashboardItemCard
                            dashboardItem={item}
                            key={item.name}
                        />
                    );
                })}
            </div>
        </div>
    );
    return (
        <Page
            contentCx={css.root}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isProtectedPage={true}
        >
            {pageContent}
        </Page>
    );
};
