import { useState, useEffect } from "react";

/** React hook for localStorage */
export function useLocalStorage<T>(
    key: string,
    initialValue: T,
): [T, (x: T) => void] {
    // Lazily initialize state
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error("Error reading localStorage key:", key, error);
            return initialValue;
        }
    });

    // Function to update the state and persist to localStorage
    const setValue = (value: T): void => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error writing to localStorage key:", key, error);
        }
    };

    // Effect to handle syncing with localStorage in case of changes
    useEffect(() => {
        try {
            const item = window.localStorage.getItem(key);
            if (item !== null && item !== JSON.stringify(storedValue)) {
                setStoredValue(JSON.parse(item));
            }
        } catch (error) {
            console.error("Error syncing localStorage key:", key, error);
        }
    }, [key, storedValue]);

    return [storedValue, setValue];
}
