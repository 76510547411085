import React from "react";
import { AppFooter, AppHeader, Page } from "../common";
import { FlexCell } from "@epam/promo";
import { LoginSection } from "./LoginSection";

export class LandingPage extends React.Component {
    renderHeader = () => {
        return <AppHeader />;
    };

    renderFooter = () => {
        return <AppFooter />;
    };

    render() {
        return (
            <Page
                renderHeader={this.renderHeader}
                renderFooter={this.renderFooter}
                isProtectedPage={false}
            >
                <FlexCell cx="landing" width="100%">
                    <LoginSection />
                </FlexCell>
            </Page>
        );
    }
}
