import { useUuiContext, UuiContexts } from "@epam/uui-core";
import {
    ErrorNotification,
    FlexRow,
    SuccessNotification,
    Text,
    WarningNotification,
} from "@epam/uui";
import { TApi } from "../data/apiDefinition";

export const useNotifications = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const showSuccess = (message: string) => {
        svc.uuiNotifications
            .show((props) => (
                <SuccessNotification {...props}>
                    <FlexRow alignItems="center">
                        <Text>{message}</Text>
                    </FlexRow>
                </SuccessNotification>
            ))
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Success notification error: ", error),
            );
    };

    const showWarning = (message: string) => {
        svc.uuiNotifications
            .show((props) => (
                <WarningNotification {...props}>
                    <FlexRow alignItems="center">
                        <Text>{message}</Text>
                    </FlexRow>
                </WarningNotification>
            ))
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Warning notification error: ", error),
            );
    };

    const showError = (message: string) => {
        svc.uuiNotifications
            .show((props) => (
                <ErrorNotification {...props}>
                    <FlexRow alignItems="center">
                        <Text>{message}</Text>
                    </FlexRow>
                </ErrorNotification>
            ))
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Error notification error: ", error),
            );
    };

    return { showSuccess, showWarning, showError };
};
