import { useEffect } from "react";
import { useAppUser } from "../helpers/appUser";
import { IHasChildren } from "@epam/uui-core";
import { svc } from "../services";

export function AppProtectedRoute(props: IHasChildren) {
    const { children } = props;
    const appUser = useAppUser();

    useEffect(() => {
        if (!appUser?.hasUser()) {
            svc.uuiRouter?.redirect({ pathname: "/" });
        }
    }, [appUser, svc]);

    if (!appUser?.hasUser()) {
        return null; // Optionally, show a loading spinner or placeholder
    }

    return <>{children}</>;
}
