import React from "react";
import { SidebarDataItem } from "./SidebarDataItem";
import { SidebarDataItemConfig } from "./SidebarDataPanel";

interface SidebarDataSectionProps<T> {
    data: T;
    fields: SidebarDataItemConfig<T>[];
    isEditable?: boolean;
}

export function SidebarDataSection<T>({
    data,
    fields,
    isEditable,
}: SidebarDataSectionProps<T>) {
    return (
        <>
            {fields.map((field, index) => (
                <SidebarDataItem
                    key={index} // Unique key for each field
                    title={field.title}
                    value={field.render ? field.render(data) : ""} // Render function handles field extraction
                    isEditable={isEditable}
                />
            ))}
        </>
    );
}
