import { IIksApiResponse } from "../baseTypes";
import { IRiskData } from "./riskTypes";

export const riskDummyData: IIksApiResponse<IRiskData[]> = {
    status: "Success",
    message: "Operation completed successfully",
    data: [
        {
            id: 39,
            number: "R1",
            type: "Portfoliomanagement",
            isImportant: false,
            description:
                "Unerwünschter oder unerlaubter Negativsaldo auf einem Kundenportfolio",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 1,
                    name: "Tief",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "automatisch generierte Liquiditätsübersicht im Kernbankensystem",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskLevel: {
                    id: 3,
                    name: "Hoch",
                },
                initiatedOrPlannedMeasures:
                    "automatisch generierte Liquiditätsübersicht im Kernbankensystem",
            },
            controls: [
                {
                    id: 72,
                    name: "Soll-Saldo Kontrolle",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                        abbreviation: "GAM",
                    },
                },
                {
                    id: 2335,
                    name: "TESTKONTROLLE aaa",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                        abbreviation: "GAM",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 40,
            number: "R100",
            type: "Risikokontrolle",
            isImportant: true,
            description:
                "Nostro-Gesamtlimiten werden nicht eingehalten - Risiko-Exposure auf der Bankbilanz, welches die Risikofähigkeit der Bank überschreitet",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 3,
                    name: "Hoch",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "Bewirtschaftung der Bilanz und ihre Kontrolle wird in zwei separaten Bereichen geführt",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures:
                    "Bewirtschaftung der Bilanz und ihre Kontrolle wird in zwei separaten Bereichen geführt",
            },
            controls: [
                {
                    id: 73,
                    name: "Überwachung Nostro-Gesamtlimiten - nominelle Limiten",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "17194faf-7e01-47bf-80e0-af88c5a6d860",
                        abbreviation: "HEN",
                    },
                },
                {
                    id: 74,
                    name: "Überwachung Nostro-Gesamtlimiten - nominelle FX-Limite",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                        abbreviation: "GAM",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 41,
            number: "R101",
            type: "Risikokontrolle",
            isImportant: true,
            description:
                "Limiten in Einzelwährungen werden nicht eingehalten - Risiko-Exposure auf der Gesamtbilanz in einer Einzelwährung, welche die Risikofähigkeit in Bezug auf eine einzelne Währung übersteigt",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "Bewirtschaftung der FX-Bestände und ihre Kontrolle wird in zwei separaten Bereichen geführt",
            },
            netAssessment: {
                damageLevel: {
                    id: 1,
                    name: "Tief",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures:
                    "Bewirtschaftung der FX-Bestände und ihre Kontrolle wird in zwei separaten Bereichen geführt",
            },
            controls: [
                {
                    id: 75,
                    name: "Überwachung Limiten für Einzelwährungen",
                    periodicity: {
                        id: 2,
                        name: "Wöchentlich",
                    },
                    responsibleUser: {
                        id: "7e562d16-7022-4409-a771-c1317c36e2f1",
                        abbreviation: "SON",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 42,
            number: "R102",
            type: "Risikokontrolle",
            isImportant: true,
            description:
                "Limite Zinssensitivität wird nicht eingehalten - Zinsänderungsrisiko auf der Gesamtbilanz, welche die Risikofähigkeit der Bank übersteigt",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures: "n/a",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures: "n/a",
            },
            controls: [
                {
                    id: 76,
                    name: "Überwachung Nostro-Gesamtlimiten - Zinsänderungsrisiken",
                    periodicity: {
                        id: 4,
                        name: "Quartalsweise",
                    },
                    responsibleUser: {
                        id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                        abbreviation: "KAT",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 43,
            number: "R103",
            type: "Risikokontrolle",
            isImportant: true,
            description:
                "Gegenpartei-Rahmenlimiten im Rahmen der Finanzanlagen werden nicht eingehalten - Finanzanlagen sind zu stark auf eine Gegenpartei konzentriert",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures: "n/a",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures: "n/a",
            },
            controls: [
                {
                    id: 77,
                    name: "Überwachung Gegenpartei-Rahmenlimiten Finanzanlagen",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "17194faf-7e01-47bf-80e0-af88c5a6d860",
                        abbreviation: "HEN",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },

        // Add more dummy data here
        {
            id: 44,
            number: "R104",
            type: "Risikokontrolle",
            isImportant: false,
            description:
                "Unzureichende Liquiditätsreserven - Risiko der Zahlungsunfähigkeit",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 3,
                    name: "Hoch",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "Tägliche Überwachung der Liquiditätsreserven",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures: "Erhöhung der Liquiditätsreserven",
            },
            controls: [
                {
                    id: 78,
                    name: "Überwachung Liquiditätsreserven",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                        abbreviation: "GAM",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 45,
            number: "R105",
            type: "Portfoliomanagement",
            isImportant: true,
            description:
                "Fehlende Diversifikation im Portfolio - Konzentrationsrisiko",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "Regelmäßige Überprüfung der Portfoliozusammensetzung",
            },
            netAssessment: {
                damageLevel: {
                    id: 1,
                    name: "Tief",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                initiatedOrPlannedMeasures: "Diversifikation des Portfolios",
            },
            controls: [
                {
                    id: 79,
                    name: "Überwachung Portfolio-Diversifikation",
                    periodicity: {
                        id: 2,
                        name: "Wöchentlich",
                    },
                    responsibleUser: {
                        id: "7e562d16-7022-4409-a771-c1317c36e2f1",
                        abbreviation: "SON",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 46,
            number: "R106",
            type: "Risikokontrolle",
            isImportant: false,
            description:
                "Unzureichende IT-Sicherheitsmaßnahmen - Risiko von Cyberangriffen",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 3,
                    name: "Hoch",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures:
                    "Implementierung von Firewalls und Antivirensoftware",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures:
                    "Regelmäßige Sicherheitsüberprüfungen",
            },
            controls: [
                {
                    id: 80,
                    name: "Überwachung IT-Sicherheitsmaßnahmen",
                    periodicity: {
                        id: 3,
                        name: "Monatlich",
                    },
                    responsibleUser: {
                        id: "17194faf-7e01-47bf-80e0-af88c5a6d860",
                        abbreviation: "HEN",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 47,
            number: "R107",
            type: "Portfoliomanagement",
            isImportant: true,
            description:
                "Fehlende Liquiditätsplanung - Risiko der Zahlungsunfähigkeit",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 3,
                    name: "Hoch",
                },
                eventLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskLevel: {
                    id: 3,
                    name: "Hoch",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures: "Erstellung eines Liquiditätsplans",
            },
            netAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                initiatedOrPlannedMeasures:
                    "Regelmäßige Aktualisierung des Liquiditätsplans",
            },
            controls: [
                {
                    id: 81,
                    name: "Überwachung Liquiditätsplanung",
                    periodicity: {
                        id: 1,
                        name: "Täglich",
                    },
                    responsibleUser: {
                        id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                        abbreviation: "GAM",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
        {
            id: 48,
            number: "R108",
            type: "Risikokontrolle",
            isImportant: false,
            description:
                "Unzureichende Compliance-Maßnahmen - Risiko von Gesetzesverstößen",
            iksObjective: null,
            isActive: true,
            grossAssessment: {
                damageLevel: {
                    id: 2,
                    name: "Mittel",
                },
                eventLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskLevel: {
                    id: 2,
                    name: "Mittel",
                },
                riskIndicatorText:
                    "1 Abwicklung / 17 Risikokontrolle und Limitenüberwachung",
                existingMeasures: "Implementierung von Compliance-Richtlinien",
            },
            netAssessment: {
                damageLevel: {
                    id: 1,
                    name: "Tief",
                },
                eventLevel: {
                    id: 1,
                    name: "Tief",
                },
                riskLevel: {
                    id: 1,
                    name: "Tief",
                },
                initiatedOrPlannedMeasures:
                    "Regelmäßige Schulungen für Mitarbeiter",
            },
            controls: [
                {
                    id: 82,
                    name: "Überwachung Compliance-Maßnahmen",
                    periodicity: {
                        id: 3,
                        name: "Monatlich",
                    },
                    responsibleUser: {
                        id: "7e562d16-7022-4409-a771-c1317c36e2f1",
                        abbreviation: "SON",
                    },
                },
            ],
            responsibleUser: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
        },
    ],
};
