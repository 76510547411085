import { IRiskData } from "../data/api";
import { DataSourceState, TableFiltersConfig } from "@epam/uui-core";

export const applyFilters = (
    data: IRiskData[],
    filtersConfig: TableFiltersConfig<IRiskData>[],
    state?: DataSourceState,
): IRiskData[] => {
    let filteredData = data;

    // DEBUG:
    // console.log("current state of filters is: ", state?.filter);

    // Apply each active filter from the state.filter object
    Object.keys(state?.filter || {}).forEach((filterField) => {
        const filterValue = state?.filter?.[filterField];
        const filterConfig = filtersConfig.find(
            (config) => config.field === filterField,
        );

        // DEBUG:
        // console.log(
        //     "current filter field: ",
        //     filterField,
        //     filterValue,
        //     filterConfig,
        // );

        // Handle the filter based on its field type
        if (filterConfig) {
            switch (filterField) {
                case "isActive":
                    // Boolean filter: isActive = true/false
                    filteredData = filteredData.filter((item) => {
                        if (filterValue === true) {
                            return item.isActive === true;
                        } else if (filterValue === false) {
                            return item.isActive === false;
                        }
                        return true; // No filter if not true/false
                    });
                    break;

                case "isImportant": {
                    if (Array.isArray(filterValue)) {
                        filteredData = filteredData.filter((item) => {
                            // Map 1 to true and 2 to false
                            const booleanValues = filterValue.map(
                                (val) => val === 1,
                            );
                            return booleanValues.includes(
                                item.isImportant ?? false,
                            );
                        });
                    }
                    break;
                }

                case "number":
                    // "in" or "nin" filter for number
                    if (filterValue?.in) {
                        filteredData = filteredData.filter((item) =>
                            filterValue.in.includes(item.number),
                        );
                    } else if (filterValue?.nin) {
                        filteredData = filteredData.filter(
                            (item) => !filterValue.nin.includes(item.number),
                        );
                    }
                    break;

                case "type":
                    // "in" or "nin" filter for type
                    if (filterValue?.in) {
                        filteredData = filteredData.filter((item) =>
                            filterValue.in.includes(item.type),
                        );
                    } else if (filterValue?.nin) {
                        filteredData = filteredData.filter(
                            (item) => !filterValue.nin.includes(item.type),
                        );
                    }
                    break;

                case "grossAssessment":
                    // Filter by grossAssessment riskLevel id
                    if (filterValue?.in) {
                        filteredData = filteredData.filter((item) =>
                            filterValue.in.includes(
                                item.grossAssessment?.riskLevel?.id,
                            ),
                        );
                    }
                    break;

                case "netAssessment":
                    // Filter by netAssessment riskLevel id
                    if (filterValue?.in) {
                        filteredData = filteredData.filter((item) =>
                            filterValue.in.includes(
                                item.netAssessment?.riskLevel?.id,
                            ),
                        );
                    }
                    break;

                case "controls":
                    // Filter by controls periodicity id
                    if (filterValue?.in) {
                        filteredData = filteredData.filter((item) =>
                            item.controls?.some((control) =>
                                filterValue.in.includes(
                                    control.periodicity?.id,
                                ),
                            ),
                        );
                    }
                    break;

                default:
                    break;
            }
        }
    });

    return filteredData;
};
