import { IProcessRequest } from "@epam/uui-core";
import { IIksApiResponse } from "../baseTypes";
import { IIksEnumsResponse } from "./enumTypes";

export function getEnumsApi(
    processRequest: IProcessRequest,
    origin: string = "",
) {
    return {
        getEnums(token: string) {
            return processRequest<IIksApiResponse<IIksEnumsResponse>>(
                origin.concat("/api/enums"),
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}
