import React from "react";
import { FlexCell, FlexRow, Text } from "@epam/uui";
import css from "./SidebarDataPanel.module.scss";

interface SidebarDataItemProps {
    title: string;
    value?: React.ReactNode;
    isEditable?: boolean;
}

export function SidebarDataItem({
    title,
    value,
    isEditable = false,
}: SidebarDataItemProps) {
    return (
        <FlexRow padding="24">
            <FlexCell shrink={0} width={162}>
                <Text color="secondary">{title}</Text>
            </FlexCell>
            {isEditable ? (
                <FlexRow>{value}</FlexRow>
            ) : (
                <Text cx={css.noWrap}>{value}</Text>
            )}
        </FlexRow>
    );
}
